import 'notyf/notyf.min.css'
import 'graphiql/graphiql.css'
import '@graphiql/plugin-explorer/dist/style.css'
import './global.css'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import getAppRouter from './app.router'
import { SchemaDesignerProvider } from './components/schema-designer/schema-designer-context'
import { enableMocking } from './mock/enable-mocking'
import UploadFileStatusProvider from './pages/files/upload-file-status-provider'
import { getAmplifyConfig } from './utils/auth/amplify-config'
import AuthContextProvider from './utils/auth/auth-context'

const amplifyConfig = getAmplifyConfig()
Amplify.configure(amplifyConfig)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: 2,
    },
  },
})

function initReact() {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <UploadFileStatusProvider>
            <SchemaDesignerProvider>
              <RouterProvider router={getAppRouter()} />
            </SchemaDesignerProvider>
          </UploadFileStatusProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </RecoilRoot>,
  )
}

enableMocking().then(initReact)
