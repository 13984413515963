import { signOut } from 'aws-amplify/auth'
import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import analytics, { EVENTS } from 'src/utils/analytics'
import { authContext, getStoredUserAuth } from 'src/utils/auth/auth-context'
import { UserData, userState } from 'src/utils/state'

export default function LogoutPage() {
  const { setUnauthStatus } = React.useContext(authContext)
  const [_user, setUserState] = useRecoilState(userState)

  useEffect(() => {
    analytics.track(EVENTS.USER_SIGN_OUT)
    const session = getStoredUserAuth()

    const cleanUp = () => {
      setUnauthStatus()
      setUserState({} as UserData)
      window.location.href = '/login'
    }

    if (session.type === 'COGNITO') {
      signOut().then(cleanUp)
    } else {
      cleanUp()
    }
  }, [])

  return null
}
