export function generateShortId(options: { existingIds: string[]; length?: number; caseSensitive?: boolean }): string {
  const { existingIds, length = 6, caseSensitive = false } = options

  // Default to lowercase only
  let characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const caseSensitiveCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  if (caseSensitive) {
    characters += caseSensitiveCharacters
  }
  const charactersLength = characters.length

  function generateRandomID(): string {
    let result = ''
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  let newID: string
  do {
    newID = generateRandomID()
  } while (existingIds.includes(newID))

  return newID
}
