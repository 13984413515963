import { useForm } from '@tanstack/react-form'
import { useMutation } from '@tanstack/react-query'
import { LakehouseDataProductInput, ShareApp, Uni } from '@vendia/management-api-types'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ButtonIconAction } from 'src/components/buttons/button-icon-action'
import Card, { CardRule } from 'src/components/containers/card'
import { ContentWindow } from 'src/components/containers/content-window'
import Form from 'src/components/fields/form'
import MonacoEditorField from 'src/components/fields/monaco-editor.field'
import Icon from 'src/components/icons/icon'
import { ProductPolicies } from 'src/components/lakehouse/product-policies'
import PageLoader from 'src/components/loaders/page-loader'
import Alert from 'src/components/messages/alert'
import ConfirmationModal from 'src/components/modals/confirmation-modal'
import PageHeader from 'src/components/page-header'
import { BasicTable } from 'src/components/tables/basic-table'
import {
  LakehouseExternalAccess,
  LakehouseShareAppConfig,
  parseLakehouseShareAppConfig,
} from 'src/pages/uni-create/utils'
import { ShareAppName } from 'src/types/types'
import { assert } from 'src/utils/assert'
import { findAwsRegionDisplayAlt } from 'src/utils/csp/regions'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseProduct } from 'src/utils/lakehouse/types'
import notify from 'src/utils/notify'

import { ExecutionStatus, Job } from './data-product-card'
import { LakehouseDataLoader } from './lakehouse-loader'
import { ShareDataProductModal } from './share-data-product-modal'
import { useLakehouseJobs } from './use-lakehouse-jobs'

enum State {
  INITIALIZED,
  FETCHING_DATA,
  DATA_FETCH_SUCCESSFUL,
  DATA_FETCH_FAILED,
}

// Both SQL safe and lowercase (for glue database/table names)
function getSafeUniNodeName(uniName: string, nodeName: string) {
  return `${uniName}_${nodeName}`.replace(/-/g, '_').replace(/\./g, '_').toLowerCase()
}

export const PageLakehouseDataProduct = () => <LakehouseDataLoader Comp={PageLakehouseDataProductContent} />

const PageLakehouseDataProductContent = ({ uni, shareApps }: { uni: Uni; shareApps: ShareApp[] }) => {
  const { product: productName } = useParams<{ product: string }>()

  const ingestionShareApp = shareApps.find(
    (shareApp) =>
      shareApp.shareAppName === ShareAppName.LakehouseIngestion ||
      shareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion ||
      shareApp.shareAppName === ShareAppName.LakehouseClouderaIngestion,
  )
  assert(ingestionShareApp, 'Ingestion share app not found')

  // Invites are inside the distribution share app config, under externalAccess
  const distributionShareApp = shareApps.find((shareApp) => shareApp.shareAppName === ShareAppName.LakehouseApp)

  assert(distributionShareApp, 'Distribution share app not found')

  const distributionShareAppConfig = useMemo(
    () => parseLakehouseShareAppConfig(distributionShareApp.shareAppConfig),
    [ingestionShareApp.shareAppConfig],
  )
  const shareAppConfig = useMemo(
    () => parseLakehouseShareAppConfig(ingestionShareApp.shareAppConfig),
    [ingestionShareApp.shareAppConfig],
  )
  const product = useMemo(
    () => shareAppConfig.products.find((p) => p.name === productName) || shareAppConfig.products[0],
    [productName, shareAppConfig],
  )

  type LakehouseData = {
    [key: string]: any
  }
  const [data, setData] = useState<LakehouseData[]>([])
  const api = useApi()
  const [state, setState] = useState(State.INITIALIZED)
  const [expanded, setExpanded] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const distributionNodes = uni.nodes?.filter((node) => node.name.includes('distribution'))
  const nodeName = distributionNodes?.find((node) => node.region === product.regions[0])?.name

  const buildSqlQuery = (uni: Uni, shareAppConfig: LakehouseShareAppConfig, product: LakehouseDataProductInput) => {
    //iceberg_test_dataset_17_unis_jakepartusch_com_distribution_node_us_east_2_data_staging_wtvn96
    const prefix = 'iceberg'
    const sourceTable = shareAppConfig.sources[0].tableDefinitions[0].tableName
    const productKey = product.key
    const safeUniNodeName = getSafeUniNodeName(uni.name, nodeName!)
    const icebergTable = `${prefix}_${safeUniNodeName}_${sourceTable}_${productKey}`.toLowerCase()
    return `SELECT * FROM ${icebergTable} limit 10;`
  }

  const form = useForm<any>({
    defaultValues: {
      warehouse_sql_editor: buildSqlQuery(uni, shareAppConfig, product),
    },
    onSubmit: async ({ value }: any) => {
      setState(State.FETCHING_DATA)
      const response = await api.fetchLakehouseData({
        uniName: uni.name,
        nodeName: nodeName!,
        dataProductKey: product.key,
        sqlText: value.warehouse_sql_editor?.trim(),
      })
      if (response.errors?.length) {
        setErrorMessage(response.errors[0].message)
        setState(State.DATA_FETCH_FAILED)
        return
      }
      setData(response.fetchLakehouseData.results)
      setState(State.DATA_FETCH_SUCCESSFUL)
    },
  })

  const columnHeaders = Object.keys(data?.[0] ?? {})
  const rows =
    data?.map((row) => {
      // map over headers to ensure order and handle missing values
      return columnHeaders.map((header) => row[header])
    }) ?? []

  useEffect(() => {
    form.handleSubmit()
  }, [])

  return (
    <ContentWindow>
      <div className='flex flex-grow flex-col gap-4'>
        <PageHeader
          title={`${productName}`}
          aboveTitle={
            <Link to='../distro-dashboard' className='text-link -mt-3 mb-6 flex items-center text-xs font-bold'>
              <Icon name='chevron-left' className={'mr-2'} size={16} />
              Return to dashboard
            </Link>
          }
          testid='product-name-header'
          image={<img src='/images/data-product.svg' alt='Data set illustration' className='size-10' />}
        />
        <div className='flex gap-6 p-6'>
          <div className='w-2/3'>
            <Card className='w-full' padding='none' overflow='visible'>
              <Form form={form}>
                <div className='flex flex-col gap-4'>
                  <div className='flex items-center justify-between p-6 text-sm font-bold'>
                    <h2>Query your data with SQL</h2>
                    <div className='flex gap-2'>
                      <ButtonIconAction icon='play' label='Run sql query' type='submit' />
                      <ButtonIconAction
                        type='button'
                        icon={expanded ? 'chevron-up' : 'chevron-down'}
                        label='Toggle sharing rule details'
                        onClick={() => setExpanded(!expanded)}
                      />
                    </div>
                  </div>
                  {expanded && (
                    <div>
                      <CardRule />
                      <div className='bg-uibg-0 p-6'>
                        <div className='grid'>
                          <MonacoEditorField
                            form={form}
                            language='sql'
                            name='warehouse_sql_editor'
                            validators={isRequiredOnBlur}
                            minHeight={150}
                            options={{
                              lineNumbers: 'on' as any,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            </Card>
            <Card className='w-full' padding='none'>
              <div className='flex flex-col gap-4'>
                {state === State.INITIALIZED && <div className='p-6'>Run a query to see results</div>}
                {state === State.FETCHING_DATA && (
                  <div className='border-uiBg-3 bg-uibg-0 flex min-h-48 items-center justify-center border text-center'>
                    <PageLoader />
                  </div>
                )}
                {state === State.DATA_FETCH_SUCCESSFUL && (
                  <div>
                    {rows.length === 0 ? (
                      <div className='border-uiBg-3 bg-uibg-0 flex min-h-48 items-center justify-center border'>
                        No data found
                      </div>
                    ) : (
                      <BasicTable columnHeaders={columnHeaders} rows={rows} />
                    )}
                  </div>
                )}
                {state === State.DATA_FETCH_FAILED && <div className='p-6'>{errorMessage}</div>}
              </div>
            </Card>
          </div>
          <div className='w-1/3 space-y-4'>
            <SyncStatusCard uni={uni} product={product} />
            <RegionCard product={product} />
            <SharingPoliciesCard product={product} />
            <InvitesCard
              product={product}
              shareAppConfig={shareAppConfig}
              distributionShareAppConfig={distributionShareAppConfig}
              uni={uni}
            />
          </div>
        </div>
      </div>
    </ContentWindow>
  )
}

const SyncStatusCard = ({ uni, product }: { uni: Uni; product: LakehouseDataProductInput }) => {
  const [expanded, setExpanded] = useState(false)
  const [showSyncModal, setShowSyncModal] = useState(false)
  const api = useApi()

  const lakehouseJobsQuery = useLakehouseJobs({ uniName: uni.name })
  const { refetch: refetchLakehouseJobs } = lakehouseJobsQuery

  const friendlyJobStatusMap: Record<ExecutionStatus, string> = {
    RUNNING: 'Running',
    SUCCEEDED: 'Success',
    FAILED: 'Failure',
    TIMED_OUT: 'Failure',
    ABORTED: 'Failure',
    PENDING_REDRIVE: 'Failure',
  }

  const jobs = lakehouseJobsQuery.data?.getLakehouseJobs?.jobs?.[product.key]

  const getUserFriendlyJobStatus = (status: ExecutionStatus): string => {
    return friendlyJobStatusMap[status]
  }

  const JobItem = ({ job }: { job: Job }) => {
    const source = job?.source

    return (
      <div className='flex items-center justify-between'>
        <div>
          {source.status === 'RUNNING' ? (
            <>
              <div className='text-base font-bold'>Started {dayjs(source.startTime).fromNow()}</div>
              <div className='text-xs font-normal'>{dayjs(source.startTime).format('MMMM D, YYYY h:mm A')}</div>
            </>
          ) : (
            <>
              <div className='text-base font-bold'>{dayjs(source.stopTime).fromNow()}</div>
              <div className='text-xs font-normal'>{dayjs(source.stopTime).format('MMMM D, YYYY h:mm A')}</div>
            </>
          )}
        </div>
        <div>
          <div
            className={`text-base font-bold ${
              source?.status === 'RUNNING'
                ? 'text-information-10'
                : source?.status === 'SUCCEEDED'
                  ? 'text-success-13'
                  : getUserFriendlyJobStatus(source?.status) === 'Failure'
                    ? 'text-error-8'
                    : ''
            }`}
          >
            {getUserFriendlyJobStatus(source?.status)}
          </div>
        </div>
      </div>
    )
  }

  const lakehouseIngestionSFNMutation = useMutation({
    mutationFn: ({ uniName, dataProductKey }: { uniName: string; dataProductKey: string }) =>
      api.invokeLakehouseIngestionSFN({ uniName, dataProductKey }),

    onError: (error) => notify.error(`Error syncing data product: ${error}`),
    onSuccess: (response) => {
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.invokeLakehouseIngestionSFN) {
        notify.error('Error syncing data product')
        return
      }
      notify.success(`Successfully initiated sync for: ${product.name}`)
      setShowSyncModal(false)
      refetchLakehouseJobs()
    },
  })

  return (
    <>
      <Card className='w-full' padding='none' overflow='visible'>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center justify-between p-6 text-sm font-bold'>
            <h4>Sync Status</h4>
            <div className='flex gap-2'>
              <ButtonIconAction icon={'refresh'} label='Sync data product' onClick={() => setShowSyncModal(true)} />
              <ButtonIconAction
                type='button'
                icon={expanded ? 'chevron-up' : 'chevron-down'}
                label='Toggle sharing rule details'
                onClick={() => setExpanded(!expanded)}
              />
            </div>
          </div>
          {expanded && (
            <div>
              <CardRule />
              <div className='bg-uibg-0 p-6'>
                <div className='grid'>
                  {!jobs ? (
                    <div className='text-gray-500'>Loading...</div>
                  ) : (
                    <ul className='space-y-4'>
                      {jobs.map((job, index) => (
                        <li key={index}>
                          <JobItem job={job} />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
      <ConfirmationModal
        isOpen={showSyncModal}
        onClose={() => setShowSyncModal(false)}
        title={`Confirm sync for ${product?.name}`}
        onSubmit={() => lakehouseIngestionSFNMutation.mutate({ uniName: uni.name, dataProductKey: product.key })}
        isSubmitting={lakehouseIngestionSFNMutation.isPending}
        actionButtonType={`primary`}
        actionButtonText={`Sync`}
        confirmationText={'sync'}
      >
        <div className='grid gap-4'>
          <p>
            Immediately begin syncing the latest data from your configured source to the <strong>{product.name}</strong>{' '}
            data product.
          </p>
          <Alert className='text-sm italic text-gray-500'>
            Note: Once started, the sync process cannot be cancelled or undone.
          </Alert>
        </div>
      </ConfirmationModal>
    </>
  )
}

const RegionCard = ({ product }: { product: LakehouseDataProductInput }) => {
  const [expanded, setExpanded] = useState(false)

  const regions = product?.regions

  const RegionItem = ({ region }: { region: string }) => {
    let regionDisplayAlt = findAwsRegionDisplayAlt(region)
    return (
      <div>
        <div className='text-base font-bold'>{regionDisplayAlt}</div>
        <div className='text-xs font-normal'>{region}</div>
      </div>
    )
  }

  return (
    <Card className='w-full' padding='none' overflow='visible'>
      <div className='flex flex-col gap-4'>
        <div className='flex items-center justify-between p-6 text-sm font-bold'>
          <h4>Regions</h4>
          <div className='flex gap-2'>
            <ButtonIconAction
              type='button'
              icon={expanded ? 'chevron-up' : 'chevron-down'}
              label='Toggle sharing rule details'
              onClick={() => setExpanded(!expanded)}
            />
          </div>
        </div>
        {expanded && (
          <div>
            <CardRule />
            <div className='bg-uibg-0 p-6'>
              <div className='grid'>
                {!regions ? (
                  <div className='text-gray-500'>Loading...</div>
                ) : (
                  <ul className='space-y-4'>
                    {regions.map((region, index) => (
                      <li key={index}>
                        <RegionItem region={region} />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

const SharingPoliciesCard = ({ product }: { product: LakehouseDataProductInput }) => {
  const [expanded, setExpanded] = useState(false)
  const navigate = useNavigate()

  const dataProduct = { ...product, ...product.tableDefinitions?.[0] } as LakehouseProduct

  return (
    <Card className='w-full' padding='none' overflow='visible'>
      <div className='flex flex-col gap-4'>
        <div className='flex items-center justify-between p-6 text-sm font-bold'>
          <h4>Sharing Policies</h4>
          <div className='flex gap-2'>
            {/* <ButtonIconAction
              icon='pencil'
              label='Edit sharing policies'
              onClick={() => navigate('../edit-products')}
            /> */}
            <ButtonIconAction
              type='button'
              icon={expanded ? 'chevron-up' : 'chevron-down'}
              label='Toggle sharing rule details'
              onClick={() => setExpanded(!expanded)}
            />
          </div>
        </div>
        {expanded && (
          <div>
            <CardRule />
            <ProductPolicies product={dataProduct} />
          </div>
        )}
      </div>
    </Card>
  )
}

const InvitesCard = ({
  product,
  shareAppConfig,
  distributionShareAppConfig,
  uni,
}: {
  product: LakehouseDataProductInput
  shareAppConfig: LakehouseShareAppConfig
  uni: Uni
  distributionShareAppConfig: LakehouseShareAppConfig
}) => {
  const [expanded, setExpanded] = useState(false)
  const [showShareDataProductModal, setShowShareDataProductModal] = useState(false)

  const api = useApi()

  const invites = distributionShareAppConfig?.externalAccess

  const RenderInviteStatus = (invite: LakehouseExternalAccess) => {
    const days = dayjs(invite.grantedEmailTokenTtl).diff(dayjs(), 'day')
    const hours = dayjs(invite.grantedEmailTokenTtl).diff(dayjs(), 'hour') % 24
    const minutes = dayjs(invite.grantedEmailTokenTtl).diff(dayjs(), 'minute') % 60

    const isExpired = dayjs(invite.grantedEmailTokenTtl).isBefore(dayjs())
    const timeLeft = days > 0 ? `${days} days left` : hours > 0 ? `${hours} hours left` : `${minutes} minutes left`

    switch (invite.status) {
      case 'PENDING_CONNECTION':
        if (isExpired) {
          return (
            <div
              className='text-link cursor-pointer text-base font-bold hover:text-[#0b2e56]'
              onClick={() => setShowShareDataProductModal(true)}
            >
              Resend
            </div>
          )
        }
        return <div className='text-base font-bold text-gray-600'>{timeLeft}</div>
      case 'CONNECTION_REQUESTED':
        return <div className='text-base font-bold text-gray-600'>Connection requested</div>
      case 'CONNECTED':
        return <div className={`text-success-13 text-base font-bold`}>Accepted</div>
      case 'CONNECTION_FAILED':
        return <div className='text-error-8 text-base font-bold'>Failed</div>
      default:
        return <div></div>
    }
  }

  const InviteItem = ({ invite }: { invite: LakehouseExternalAccess }) => {
    return (
      <div className='flex items-center justify-between gap-3 text-nowrap'>
        <div className='max-w-72 truncate text-base font-bold'>{invite.grantedEmailAddress}</div>
        <div>{RenderInviteStatus(invite)}</div>
      </div>
    )
  }

  return (
    <>
      <Card className='w-full' padding='none' overflow='visible'>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center justify-between p-6 text-sm font-bold'>
            <h4>Invites</h4>
            <div className='flex gap-2'>
              <ButtonIconAction
                icon={'share'}
                label='Share data product'
                onClick={() => {
                  setShowShareDataProductModal(true)
                }}
              />
              <ButtonIconAction
                type='button'
                icon={expanded ? 'chevron-up' : 'chevron-down'}
                label='Toggle sharing rule details'
                onClick={() => setExpanded(!expanded)}
              />
            </div>
          </div>
          {expanded && (
            <div>
              <CardRule />
              <div className='bg-uibg-0 p-6'>
                <div className='grid'>
                  {!invites ? (
                    <div>No invites found</div>
                  ) : (
                    <ul className='space-y-4'>
                      {invites.map((invite, index) => (
                        <li key={index}>
                          <InviteItem invite={invite} />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
      <ShareDataProductModal
        uni={uni}
        isOpen={showShareDataProductModal}
        onClose={() => setShowShareDataProductModal(false)}
        shareAppConfig={shareAppConfig}
        defaultProductKey={product.key}
        onlyAllowDefaultProduct={true}
      />
    </>
  )
}
