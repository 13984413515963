import { useContext } from 'react'
import Balancer from 'react-wrap-balancer'

import { EmptyEntityView } from './empty-entity-view'
import { EntityAttributesList } from './entity-attributes-list'
import { EntityModal } from './entity-modal'
import { EntityTopNav } from './entity-top-nav'
import { FieldModal } from './field-modal'
import { ParsedFileModal } from './parsed-file-modal'
import { SchemaDesignerContext, SchemaDesignerContextType } from './schema-designer-context'
import { Status } from './status'

export const SchemaDesigner = () => {
  const {
    selectedEntityFieldKeys,
    status,
    selectedEntity,
    selectedFieldKey,
    noEntitiesCreated,
    readOnlyMode,
    dataModelMode,
  } = useContext(SchemaDesignerContext) as SchemaDesignerContextType

  return (
    <>
      <EntityModal
        title={selectedEntity ? 'Edit entity' : 'Create a new entity'}
        isOpen={status === Status.SHOW_ENTITY_MODAL_NEW || status === Status.SHOW_ENTITY_MODAL_EDIT}
      />
      <FieldModal
        title={selectedFieldKey ? 'Edit attribute' : 'Add attribute'}
        isOpen={status === Status.SHOW_FIELD_MODAL_NEW || status === Status.SHOW_FIELD_MODAL_EDIT}
      />
      <ParsedFileModal
        title='Which column is the unique identifier?'
        isOpen={status === Status.SHOW_PARSED_FILE_MODAL}
      />
      <div className='flex w-full flex-1 flex-col'>
        {readOnlyMode && noEntitiesCreated ? (
          /* Quick solution to show something for the rare Unis without any entities */
          <div>This Uni does not have any entities.</div>
        ) : (
          <>
            {!dataModelMode && <EntityTopNav />}
            <div className='flex w-full flex-1'>
              {noEntitiesCreated ? (
                <div className='flex flex-1 flex-col'>
                  <p className='text-neutral-8 -mb-4 pl-48'>
                    Click the <strong>Add entity</strong> button to get started!
                  </p>
                  <div className='px-6'>
                    <img
                      className='w-[500px]'
                      src='/images/create-entity.svg'
                      alt='A graph showing a Product "entity" at the top and four "attributes" below it - SKU, Name, Price, and Color - and an arrow pointing to the Add entity button'
                    />
                  </div>
                </div>
              ) : (
                <>{selectedEntityFieldKeys.length ? <EntityAttributesList /> : <EmptyEntityView />}</>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}
