export type IllustrationProps = {
  overImageContent?: React.ReactNode
  children: React.ReactNode
}

export const Illustration = ({ children, overImageContent }: IllustrationProps) => (
  <div className='flex flex-grow'>
    {/* form column */}
    <div className='flex w-1/2 flex-grow flex-col items-center bg-stone-100'>
      <div className='flex w-full max-w-2xl flex-grow flex-col justify-between gap-12 px-12 lg:max-w-lg 2xl:max-w-2xl'>
        <div className='mt-12'>
          <a href='https://www.vendia.com'>
            <img className='w-32' src='/logo.svg' alt='logo' />
          </a>
        </div>
        <div className=''>{children}</div>
        <div>{/* placeholder div for easy flex layout with justify-between */}</div>
      </div>
    </div>

    {/* illustration column */}
    <div className='hidden w-1/2 flex-col bg-white lg:flex'>
      <div
        className='flex flex-grow flex-col items-center bg-cover bg-center'
        style={{ backgroundImage: "url('/images/login-illustration.jpg')" }}
      >
        {/* h-1/2 keeps the text over the top half of the image (not over computer screens) */}
        <div className='flex h-1/2 flex-col items-center justify-end gap-6 px-12'>{overImageContent}</div>
      </div>
      <div className='flex w-full justify-center p-12 pb-16'>
        <div className='flex w-2/3 flex-col gap-6'>
          <p className='text-xs tracking-widest opacity-80'>TRUSTED BY INDUSTRY LEADERS:</p>
          <img className='' src='/images/vendia-customer-logos.png' alt='Vendia customer logos' />
        </div>
      </div>
    </div>
  </div>
)
