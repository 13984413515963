import clsx from 'clsx'
import { useState } from 'react'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import Icon from 'src/components/icons/icon'
import EnterpriseModal from 'src/components/modals/enterprise-modal'
import { Tier } from 'src/utils/subscription'

export const ENTERPRISE_TIER_FEATURES = [
  'Immutable and verifiable ledger of all transactions',
  'Unlimited Database, File storage, and transactions',
  'Real-time notifications and support for GraphQL subscriptions',
  'Guaranteed single tenancy cloud account for each node',
  'Role-based access control, including support for administrative roles',
  'Custom Authentication',
  '99.9% Availability SLA',
]

type TierCardProps = {
  isCurrentTier: boolean
  isHighlighted: boolean
  title: string
  features: string[]
  actionButton: React.ReactNode
}

function TierCard({ isCurrentTier, isHighlighted, title, features, actionButton }: TierCardProps) {
  const cardClasses = clsx('border-2 border-gray-200 shadow-none', isHighlighted && 'bg-gray-50')

  return (
    <Card childrenWrapperClassName='h-full' className={cardClasses}>
      <div className='flex h-full flex-col justify-between p-6'>
        <div>
          <div className='text-sm'>{isCurrentTier ? '30 day trial' : 'Upgrade your account to'}</div>
          <h2 className='m-0 mb-5 text-2xl font-semibold'>{title}</h2>
        </div>
        <ul>
          {features.map((feature, i) => (
            <li key={i} className='flex items-center gap-1 pb-4 text-sm'>
              <Icon name='list' size={18} /> {feature}
            </li>
          ))}
          <li>
            <a
              className='text-link flex items-center gap-1 pb-6 text-sm'
              href='https://www.vendia.com/pricing'
              target='_blank'
              rel='noreferrer'
            >
              <Icon size={18} name='external' /> See full list
            </a>
          </li>
        </ul>
        <div>{actionButton}</div>
      </div>
    </Card>
  )
}

enum Status {
  INITIALIZED,
  ENTERPRISE_INIT_SUCCESS,
}

function SubscriptionTiers({ tiers }: { tiers: Tier[] }) {
  const [status, setStatus] = useState<Status>(Status.INITIALIZED)

  return (
    <div className='mx-auto max-w-7xl'>
      <div className={'grid gap-4 p-6'} data-testid='subscription-tiers'>
        {tiers.includes(Tier.ENTERPRISE) && (
          <TierCard
            isCurrentTier={false}
            isHighlighted={false}
            title='Enterprise'
            features={ENTERPRISE_TIER_FEATURES}
            actionButton={
              <Button
                kind='secondary'
                onClick={() => setStatus(Status.ENTERPRISE_INIT_SUCCESS)}
                data-testid='upgrade-to-enterprise-button'
              >
                Upgrade to Enterprise
              </Button>
            }
          />
        )}
      </div>
      <div className='flex justify-center p-3 text-xs'>
        <a
          className="gap-1' inline-flex items-center"
          href='https://www.vendia.com/pricing'
          target='_blank'
          rel='noreferrer'
        >
          Visit our pricing page for a detailed comparison of plans and metered usage rates&nbsp;
          <Icon name='external' size={'xxs'} />
        </a>
      </div>
      <EnterpriseModal
        isOpen={status === Status.ENTERPRISE_INIT_SUCCESS}
        isTrialExpired={false}
        onClose={() => setStatus(Status.INITIALIZED)}
      />
    </div>
  )
}

export default SubscriptionTiers
