import { useForm } from '@tanstack/react-form'
import { confirmSignIn, getCurrentUser } from 'aws-amplify/auth'
import { useEffect, useRef, useState } from 'react'
import { formatLeadSourceFields } from 'src/utils/analytics/plugins/enrich-hubspot'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import notify from 'src/utils/notify'

import Button from '../buttons/button'
import Form from '../fields/form'
import TextField from '../fields/text.field'
import MarketingFields from './marketing-fields'

export default function ConfirmMFAForm({ onAuthSuccess }: { onAuthSuccess: any }) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const codeInput = useRef<any>(null)

  useEffect(() => {
    if (codeInput && codeInput.current.textInput) {
      setTimeout(() => codeInput.current.focus(), 10)
    }
  }, [])

  const heading = (
    <div style={{ marginBottom: '1rem' }}>
      2-factor authentication is enabled for this account. Please enter the code from your device.
    </div>
  )
  const renderError = error ? <div style={{ marginBottom: '1rem' }}>{error}</div> : null
  const renderInstruction = !error ? heading : null

  const form = useForm<any>({
    onSubmit: async ({ value }: any) => {
      setError(null)
      const code: string = value.code || ''

      if (!code || code.length < 5) {
        notify.error('Code enter is incorrect')
        return
      }

      setIsLoading(true)
      try {
        await confirmSignIn({ challengeResponse: code })
        const cognitoUser = await getCurrentUser()

        setIsLoading(false)

        // Successful login! Redirect to home
        onAuthSuccess({
          type: 'mfa',
          user: cognitoUser,
          identifyAttrs: formatLeadSourceFields(value),
        })
        return
      } catch (err: any) {
        setIsLoading(false)

        if (err.code === 'CodeMismatchException') {
          setError('Incorrect Code, Please try again')
          return
        }
        // Invalid session for the user, session is expired.
        if (err.code === 'NotAuthorizedException') {
          setError('Session has timed out, Please refresh and login again.')
          return
        }

        setError(JSON.stringify(err))
      }
    },
  })

  return (
    <div>
      {renderError}
      {renderInstruction}
      <Form form={form}>
        <TextField
          form={form}
          ref={codeInput}
          name='code'
          label='MFA Code'
          placeholder='Enter the 2 factor auth code here'
          validators={isRequiredOnBlur}
        />
        {/* Get remote __ref_first from site and pass through form */}
        <MarketingFields form={form} />

        <Button
          className='border-box mb-4 w-[327px] lg:w-[368px]'
          icon={isLoading ? 'refresh' : null}
          type='submit'
          iconProps={{
            isSpinning: isLoading,
            size: 15,
          }}
        >
          {isLoading ? 'Confirming...' : 'Submit 2FA code'}
        </Button>
      </Form>
    </div>
  )
}
