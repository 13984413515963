import { useForm } from '@tanstack/react-form'
import {
  AuthError,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  signIn,
  signOut,
  updateUserAttributes,
} from 'aws-amplify/auth'
import debug from 'debug'
import { JWTPayload } from 'jose'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDebounce } from 'react-use'
import _config from 'src/config'
import analytics, { EVENTS } from 'src/utils/analytics'
import { formatLeadSourceFields } from 'src/utils/analytics/plugins/enrich-hubspot'
import { authContext } from 'src/utils/auth/auth-context'
import { redirectToOrgSSO, resendVerificationEmail } from 'src/utils/auth/cognito'
import { getProvidersForDomain } from 'src/utils/auth/domain-api'
import useHashParam from 'src/utils/hooks/use-hash-param'
import { getRedirectUrl } from 'src/utils/hooks/use-track-last-visited'
import useUrlSearchParams from 'src/utils/hooks/use-url-search-params'
import { captureException } from 'src/utils/misc/sentry'
import notify from 'src/utils/notify'

import { isTruthyString } from '../../utils/auth/auth'
import Button from '../buttons/button'
import Form from '../fields/form'
import TextField from '../fields/text.field'
import ConfirmMFAForm from './confirm-mfa'
import ForceResetForm from './force-password-reset'
import styles from './login-form.module.css'
import MarketingFields from './marketing-fields'

const logger = debug('auth:login')

export function EmailConfirmationMessage({ email }: { email: string | null }) {
  const emailText = email ? ` at ${email}` : ''
  return (
    <div>
      <h3>Email confirmation sent</h3>
      <div className={styles.introText}>
        <p>A new confirmation email has been sent to you{emailText}. Please check your inbox & spam folder.</p>
        <p>If you do not see this email, contact support.</p>
      </div>
    </div>
  )
}

export function EmailNotVerifiedMessage({
  email,
  handleResendEmail,
}: {
  email: string | null
  handleResendEmail: (email: string) => void
}) {
  return (
    <div>
      <h3>Email confirmation required</h3>
      <div className={styles.introText}>
        <p>It looks like your email has not been verified.</p>
        <p>
          To login, please verify your email address by clicking confirm link in the signup email sent to{' '}
          <strong>{email}</strong>
        </p>
        <p>
          {"If you don't see email, please check your spam folder or "}
          <strong onClick={() => handleResendEmail(email!)} style={{ color: 'blue', cursor: 'pointer' }}>
            click here to request a new verification email
          </strong>
          .
        </p>
      </div>
    </div>
  )
}

export type EmailPasswordFormProps = {
  email: string
  password: string
}

function LoginForm() {
  const navigate = useNavigate()
  const location = useLocation()
  const { setAuthStatus } = React.useContext(authContext)
  const [params] = useHashParam()
  const [isLoading, setIsLoading] = useState(false)
  const [isForceReset, setForceReset] = useState(false)
  const [isMfaLogin, setMfaLogin] = useState(false)
  const [needsEmailConfirmation, setNeedsEmailConfirmation] = useState<string | null>(null)
  const [emailConfirmationSent, setEmailConfirmationSent] = useState(false)
  const urlSearchParams = useUrlSearchParams()
  const [promptForPassword, setPromptForPassword] = useState(false)
  const [showSSOButton, setShowSSOButton] = useState(false)
  const [emailToCheck, setEmailToCheck] = useState('')
  const signUpUrl = useMemo(() => getSignupUrl(), [location])
  useDebounce(onEmailEnteredDebounced, 500, [emailToCheck])

  const emailInput = useRef<HTMLInputElement | null>(null)
  const passwordInput = useRef<HTMLInputElement | null>(null)

  const form = useForm<EmailPasswordFormProps>({
    onSubmit: async ({ value }) => {
      return promptForPassword ? handleLogin(value) : handleEmailSubmit(value.email)
    },
  })

  async function authSuccess({ identifyAttrs = {} }: { identifyAttrs?: any }) {
    const user = await getCurrentUser()
    const attributes = await fetchUserAttributes()
    const session = await fetchAuthSession()

    logger('authSuccess user', user)
    logger('authSuccess attributes', attributes)
    logger('authSuccess session', session)

    const userId = attributes.sub!
    const currentAnonId = analytics.user('anonymousId')

    // local anon id and user anonId don't match, update local value.
    if (currentAnonId && attributes['custom:anonId'] && currentAnonId !== attributes['custom:anonId']) {
      logger('authSuccess anonId change, set to', attributes['custom:anonId'])
      // @ts-ignore - setAnonymousId is not in the types
      analytics.setAnonymousId(attributes['custom:anonId'])
    }

    /* User has 'custom:anonId' defined, set it to user attribute in cognito */
    if (attributes && !attributes['custom:anonId']) {
      logger('set custom:anonId in cognito', currentAnonId)
      try {
        await updateUserAttributes({
          userAttributes: {
            'custom:anonId': currentAnonId,
          },
        })
      } catch (err) {
        logger('Auth.updateUserAttributes failed', err)
      }
    }

    setAuthStatus({
      userId,
      email: attributes.email!,
      idToken: session.tokens?.idToken?.toString()!,
      accessToken: {
        jwtToken: session.tokens?.accessToken.toString()!,
        payload: session.tokens?.accessToken.payload!,
      },
      authenticated: true,
      type: 'COGNITO',
      emailVerified: isTruthyString(attributes.email_verified),
      contactDetails: {},
    })

    const identifyPayload = {
      ...attributes,
      ...identifyAttrs,
    }
    // Identify user with analytics tools
    analytics.identify(userId, identifyPayload)
    // Fire tracking event
    analytics.track(EVENTS.USER_SIGN_IN, {
      // Not sure what 'normal' means here, but keeping it around as it's always been this way
      authType: 'normal',
    })
    // Redirect
    if (urlSearchParams.get('redirect')) {
      navigate(location.search?.split('redirect=')?.[1]) //use location.search so that encoding is kept
      return
    }
    const redirectUrl = getRedirectUrl(attributes.email!) ?? '/'
    navigate(redirectUrl)
  }

  function handleRequestVerificationEmail(email: string) {
    resendVerificationEmail({
      email,
      onLoading: (_isLoading) => setIsLoading(_isLoading),
      onSuccess: (_resp) => setEmailConfirmationSent(true),
      onError: (e) => {
        console.log('e', e)
      },
    })
  }

  useEffect(() => {
    if (params.u && params.t) {
      logger('temp password & email found. Force password reset')
      setTimeout(() => {
        form.setFieldValue('email', window.atob(params.u))
        form.setFieldValue('password', params.t)
        const button: NodeListOf<HTMLButtonElement> | null = document.querySelectorAll('.login-button')
        if (button && button[0]) {
          button[0].click()
        }
      }, 10)
    } else if (params.email) {
      // Users directed here from a confirmation email link will always provide a password
      setPromptForPassword(true)
      // TODO Last pass still messing with this
      setTimeout(() => {
        form.setFieldValue('email', params.email)
        form.setFieldValue('password', '')
        passwordInput.current?.focus()
      }, 10)
    } else {
      emailInput?.current?.focus()
    }
  }, [params])

  async function checkProvidersForDomain(domain: string) {
    setIsLoading(true)

    try {
      const providers = await getProvidersForDomain(domain)

      if (providers.includes('SSO') && providers.includes('COGNITO')) {
        setShowSSOButton(true)
        setPromptForPassword(true)
      } else if (providers.includes('SSO')) {
        await redirectToOrgSSO(domain)
      } else {
        setPromptForPassword(true)
      }
    } catch (e) {
      console.error(e)
      setPromptForPassword(true)
      setShowSSOButton(false)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleEmailSubmit(email: string) {
    const domain = email?.split('@')[1]
    checkProvidersForDomain(domain)
  }

  async function loginWithSSO(email: string = '') {
    const domain = email.split('@')[1]

    if (!domain) {
      notify.error('Please enter a valid email address')
      return
    }

    await redirectToOrgSSO(domain)
  }

  function onEmailEnteredDebounced() {
    if (emailToCheck) {
      checkProvidersForDomain(emailToCheck?.split('@')[1])
    }
  }

  async function handleLogin(data = { email: '', password: '' }) {
    const { email, password } = data

    if (!email) {
      emailInput.current?.focus()
      return
    }

    if (!password) {
      passwordInput.current?.focus()
      return
    }

    setIsLoading(true)

    try {
      const signInOutput = await signIn({ username: email, password })
      const signInStep = signInOutput.nextStep.signInStep
      logger('signInOutput', signInOutput)

      /* Clear all notices */
      notify.dismissAll()

      /* If MFA flow active */
      if (signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE' || signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
        setIsLoading(false)
        setMfaLogin(true)
      } else if (signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        /* If user manually created and a force reset of password is required */
        setForceReset(true)
        // ^ This will show force reset password form
      } else if (signInStep === 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION') {
        logger('User must setup MFA')
        // If MFA is required this will be the case.
        // Auth.setupTOTP(user);
      } else if (signInStep === 'RESET_PASSWORD') {
        // Error message means they should have received an email to reset their password with a link with unique code
        notify.error('Password reset has been requested. Please check your email for instructions.')
      } else {
        /* Successful login! */
        authSuccess({
          identifyAttrs: formatLeadSourceFields(data),
        })
      }
      // // todo remove props.useHas
    } catch (e) {
      setIsLoading(false)

      logger('Login Error', e)

      // All amplify errors get classed as AuthError, but in unlikely case it's not, just send to sentry
      if (!(e instanceof AuthError)) {
        // Send error to sentry
        captureException(e)
        return
      }

      const authError = e as AuthError

      // Track errors
      analytics.track(EVENTS.USER_AUTH_ERROR, {
        context: 'login',
        code: authError.name,
      })

      if (authError.name === 'UserAlreadyAuthenticatedException') {
        // User is already logged in with a different account (unusual, likely when a dev changes environments)
        logger('UserAlreadyAuthenticatedException, sign out and try again')
        await signOut()
        return handleLogin(data)
      }

      // Email/password wrong
      if (authError.name === 'NotAuthorizedException') {
        notify.error('Invalid email or password. Please try again')
        // @TODO report potential bad actor?
      }
      if (authError.name === 'InvalidParameterException') {
        notify.error('Invalid username or password. Please try again')
      }
      // Account requires a password reset, please reset your password before attempting to login
      if (authError.name === 'PasswordResetRequiredException') {
        // 'NewPasswordRequired'
        notify.error('Password reset required')
        // redirect to forgot/reset
      }
      // User doesn't exist yet
      if (authError.name === 'UserNotFoundException') {
        navigate(`/signup#email=${email}&signup=true`)
        return
      }
      // User not verified in cognito yet
      if (authError.name === 'UserNotConfirmedException') {
        // alert('Please verify your email')
        setNeedsEmailConfirmation(email)
      }
      // Cognito pools are gone 🔥
      if (authError.name === 'ResourceNotFoundException') {
        // Send error to sentry
        captureException(authError)
        notify.error('Something bad happened. Please contact support. Error code foxtrox')
      }
      // Offline
      if (authError.name === 'NetworkError') {
        notify.error('Unable to login due to internet connection timeout')
      }
      // Too many requests
      if (authError.name === 'LimitExceededException') {
        notify.error('Too many resets were requested. Please try again in an hour')
      }
    }
  }

  function handleForgotPasswordLink() {
    const email = form.getFieldValue('email')
    if (email) {
      navigate(`/login/reset/#email=${email}`)
    } else {
      navigate('/login/reset/')
    }
  }

  function getSignupUrl() {
    // const currentUrl = new URL(location.pathname)
    if (!location) {
      return null
    }

    if (location.pathname === '/login') {
      return `/signup${location.search}`
    }
    const redirect = `${location.pathname}${location.search}`
    return `/signup?redirect=${redirect}`
  }

  let newUserMessage
  if (params.newUser) {
    newUserMessage = (
      <div className={styles.introText}>
        <p>Welcome to Vendia! Your email has been verified. You can now login to your new account</p>
      </div>
    )
  }
  if (params.createRedirect) {
    newUserMessage = <div className={styles.introText}>Please login to your account</div>
  }

  let resetCompleteMessage
  if (params.reset) {
    resetCompleteMessage = (
      <div className={styles.introText}>
        <b>Password successfully reset.</b> Please login below
      </div>
    )
  }

  const onEmailChanged = (email: string = '') => {
    const errors = form.getFieldMeta('email')?.errors
    if (errors && errors.length) {
      setIsLoading(false)
    } else {
      setEmailToCheck(email)
    }
  }

  let formRender = (
    <Form<EmailPasswordFormProps> form={form}>
      <div className='flex flex-row divide-x-2'>
        <div className={`w-full ${promptForPassword ? 'pr-5' : ''}`}>
          <div>
            <TextField
              form={form}
              name='email'
              label='Email address'
              type='email'
              wrapperClassName='mb-5'
              onChange={promptForPassword ? onEmailChanged : undefined}
              useNestedLabel
              ref={emailInput}
            />
            {promptForPassword && (
              <>
                <TextField
                  form={form}
                  name='password'
                  label='Password'
                  type='password'
                  wrapperClassName='mb-2'
                  useNestedLabel
                  ref={passwordInput}
                />
                <div className={styles.forgotPassword} onClick={handleForgotPasswordLink}>
                  Forgot password?
                </div>
              </>
            )}
            {/* Get remote __ref_first from site and pass through form */}
            <MarketingFields form={form} />

            <div className='flex justify-between gap-2'>
              <Button
                className={`${styles.button} login-button`}
                icon={isLoading ? 'refresh' : null}
                disabled={isLoading}
                name='login-continue'
                type='submit'
                iconProps={{
                  isSpinning: isLoading,
                  size: 15,
                }}
              >
                {promptForPassword ? 'Log in' : 'Continue'}
              </Button>
            </div>
          </div>
        </div>
        {showSSOButton && (
          <div className='h-100 flex w-full items-center pl-5'>
            <Button
              className={`${styles.button} login-button`}
              icon={isLoading ? 'refresh' : 'external'}
              disabled={isLoading}
              type='button'
              kind={'secondary'}
              iconProps={{
                isSpinning: isLoading,
                size: 15,
              }}
              onClick={(e) => {
                e.preventDefault()
                loginWithSSO(form.state.values.email)
              }}
            >
              Log in with SSO
            </Button>
          </div>
        )}
      </div>
    </Form>
  )

  const signUp = (
    <div>
      <hr className={styles.horizontalRule} />
      <div className={styles.signUp}>
        <p>New to Vendia Share?</p>
        {signUpUrl && (
          <Link className={styles.newAccount} to={signUpUrl} data-testid={'create-account-link'}>
            Create a new account
          </Link>
        )}
      </div>
    </div>
  )

  /* Reset email sent to user, show message */
  if (emailConfirmationSent) {
    return <EmailConfirmationMessage email={needsEmailConfirmation} />
  }

  /* UserNotConfirmedException error, show "resend email" message */
  if (Boolean(needsEmailConfirmation)) {
    return <EmailNotVerifiedMessage email={needsEmailConfirmation} handleResendEmail={handleRequestVerificationEmail} />
  }

  /* If force reset set, show password reset form */
  if (isForceReset) {
    formRender = <ForceResetForm />
  }

  /* If MFA is set, force MFA code form */
  if (isMfaLogin) {
    formRender = <ConfirmMFAForm onAuthSuccess={authSuccess} />
  }

  return (
    <div data-testid='form-login'>
      <div className='mb-16 flex flex-col gap-2'>
        <h1 className='text-3xl font-semibold'>Welcome back</h1>
        <p>Log in to your account below</p>
      </div>
      {newUserMessage}
      {resetCompleteMessage}
      {formRender}
      {signUp}
    </div>
  )
}

export default LoginForm
