import { useQueryClient } from '@tanstack/react-query'
import { OrgOnboardingStatus } from '@vendia/management-api-types'
import debug from 'debug'
import { Reducer, useReducer } from 'react'
import useApi from 'src/utils/hooks/use-api'
import useGetCurrentVendiaUserQuery from 'src/utils/hooks/use-current-vendia-user-query'
import { refetchOrg, useGetOrg } from 'src/utils/hooks/use-get-org'
import { refetchOrgDetails } from 'src/utils/hooks/use-get-org-details'
import { captureException } from 'src/utils/misc/sentry'
import notify from 'src/utils/notify'

import BasicModal from '../modals/modal.basic'
import { StepIntro } from './intro.step'
import { StepOrgLogo } from './org-logo.step'
import { StepOrgName } from './org-name.step'
import { StepWrapUp } from './wrap-up.step'

export const logger = debug('app:OrgOnboardingModal')

interface Props {
  isOpen: boolean
  closeModal: () => void
}

export enum OrgOnboardingStep {
  INTRO,
  SET_NAME,
  SET_LOGO,
  WRAP_UP,
}

export interface ModalState {
  step: OrgOnboardingStep
}

export const OrgModalInner = ({ children }: { children: React.ReactNode }) => (
  <BasicModal.Body title={'Create a Vendia Organization'}>
    {/* min height to keep modal about the same height on steps w less content */}
    <div className='flex min-h-[420px] w-full items-center justify-center'>{children}</div>
  </BasicModal.Body>
)

export function OrgOnboardingModal({ isOpen, closeModal }: Props) {
  const api = useApi()
  const queryClient = useQueryClient()
  const getOrg = useGetOrg()
  const org = getOrg?.data?.getOrganization
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const user = getCurrentVendiaUserQuery?.data?.getUser
  const emailDomain = user?.email?.split('@')[1]

  const [{ step }, setModalState] = useReducer<Reducer<ModalState, Partial<ModalState>>>(
    (state, newState) => {
      logger('setModalState:', { newState, state })
      const mergedState = { ...state, ...newState }
      // Central place to perform validation and side effects...

      // Finally, update the state
      return mergedState
    },
    {
      step: OrgOnboardingStep.INTRO,
    },
  )

  async function dismissModal() {
    closeModal()
    await getOrg.refetch()
    queryClient.invalidateQueries({ queryKey: ['isOrgMember', org?.orgId] })
    // If we shoved this modal in user's face and they've dismissed it, save that to back-end
    if (org?.onboardingStatus === OrgOnboardingStatus.OnboardingNotNotified) {
      // TODO: replace with share-sdk method?
      try {
        const res = await api.graphqlFetch({
          query: /* GraphQL */ `
            mutation SetOrgOnboardingStatus($status: OrgOnboardingStatus!) {
              setOrgOnboardingStatus(status: $status)
            }
          `,
          variables: {
            status: OrgOnboardingStatus.OnboardingNotCompleted,
          },
        })
        logger('setOrgOnboardingStatus res is', res)
      } catch (err) {
        // Capture error quietly in production
        captureException(err)
        logger('error setting org onboarding status', err)
      }
      notify.success({
        message:
          'You can always create an Org later from Account settings which can be accessed using the menu in the top-right corner of this page.',
        duration: 10 * 1000,
      })
      await new Promise((resolve) => setTimeout(resolve, 1000))
      await refetchOrg({ queryClient })
      await refetchOrgDetails({ queryClient })
    }
  }

  function onClose() {
    logger('onClose event fired (requiring user to explicitly dismiss modal)')
  }

  if (!org) {
    return null
  }

  return (
    <BasicModal.Wrapper isOpen={isOpen} onClose={onClose} className='xl:max-w-6xl'>
      {step === OrgOnboardingStep.INTRO && (
        <StepIntro org={org} emailDomain={emailDomain!} setModalState={setModalState} dismissModal={dismissModal} />
      )}
      {step === OrgOnboardingStep.SET_NAME && (
        <StepOrgName org={org} setModalState={setModalState} dismissModal={dismissModal} />
      )}
      {step === OrgOnboardingStep.SET_LOGO && (
        <StepOrgLogo org={org} setModalState={setModalState} dismissModal={dismissModal} />
      )}
      {step === OrgOnboardingStep.WRAP_UP && (
        <StepWrapUp org={org} setModalState={setModalState} dismissModal={dismissModal} />
      )}
    </BasicModal.Wrapper>
  )
}
