import { useForm } from '@tanstack/react-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ActionEnum } from '@vendia/management-api-types'
import debug from 'debug'
import { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Link } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import { ButtonPencil } from 'src/components/buttons/button-pencil'
import CopyToClipboardButton from 'src/components/buttons/copy-to-clipboard-button'
import Card from 'src/components/containers/card'
import Form from 'src/components/fields/form'
import TextField from 'src/components/fields/text.field'
import Icon from 'src/components/icons/icon'
import PageLoader from 'src/components/loaders/page-loader'
import OrgLogoBadge from 'src/components/messages/org-logo-badge'
import BasicModal from 'src/components/modals/modal.basic'
import inputValidation from 'src/utils/form/input.validation'
import { createOnBlurValidator } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { GetOrg, refetchOrg } from 'src/utils/hooks/use-get-org'
import { refetchOrgDetails, useGetOrgDetails } from 'src/utils/hooks/use-get-org-details'
import useRolePermits from 'src/utils/hooks/use-role-permits'
import { POSTFIX, useDocumentTitle } from 'src/utils/hooks/use-title'
import notify from 'src/utils/notify'

import Subscription from '../settings/subscription.page'

const logger = debug('app:OrgOverview')

export default function OrgOverview() {
  const getOrgDetails = useGetOrgDetails()
  const org = getOrgDetails?.data?.getOrganization
  const canEditOrgNameAndLogo = useRolePermits({ action: ActionEnum.OrgAll })
  const canListUsers = useRolePermits({ action: ActionEnum.OrgListUsers })
  const canLinkToProjects = Boolean(org?.unis?.length)
  const users = org?.members
  const [updatingLogo, setUpdatingLogo] = useState(false)
  const [updatingName, setUpdatingName] = useState(false)
  useDocumentTitle(`Org Overview - ${org?.name} - ${POSTFIX}`)

  if (!org || (canListUsers && !users)) {
    return (
      <div className='grid place-items-center'>
        <PageLoader />
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex gap-6'>
        {canListUsers && (
          <>
            <Card className='w-1/3'>
              <Link to='/org/members'>
                <div className='text-purple-1200 mb-4 text-5xl'>{users?.length}</div>
                <div className='text-lg font-bold'>Members</div>
              </Link>
            </Card>
            <Card className='w-1/3'>
              <Link to='/org/members'>
                <div className='text-purple-1200 mb-4 text-5xl'>{org?.pendingInvites?.length}</div>
                <div className='text-lg font-bold'>Pending invites</div>
              </Link>
            </Card>
          </>
        )}
        <Card className='w-1/3'>
          {canLinkToProjects ? (
            <Link to='/org/projects'>
              <div className='text-purple-1200 mb-4 text-5xl'>{org?.unis?.length}</div>
              <div className='text-lg font-bold'>Projects</div>
            </Link>
          ) : (
            <>
              <div className='mb-4 text-5xl text-purple-900'>0</div>
              <div className='text-lg font-bold'>Projects</div>
            </>
          )}
        </Card>
      </div>
      <Card title='Organization details'>
        <div className='flex flex-col gap-6'>
          <div>
            <div className='flex items-center gap-1 font-bold'>
              Name{' '}
              {canEditOrgNameAndLogo && <ButtonPencil label='Edit org name' onClick={() => setUpdatingName(true)} />}
            </div>
            <div>{org?.name}</div>
          </div>
          <div>
            <div className='font-bold'>Domains</div>
            <div>
              {org?.domains && org?.domains?.length > 0 ? (
                org.domains.join(', ')
              ) : (
                <span className='text-neutral-6 italic'>Awaiting domain approval</span>
              )}
            </div>
          </div>
          <div>
            <div className='flex items-center gap-1 font-bold'>
              Logo{' '}
              {canEditOrgNameAndLogo && (
                <ButtonPencil label='Edit org logo' onClick={async () => setUpdatingLogo(true)} />
              )}
            </div>
            {org?.logo ? (
              <div className='mt-1 flex gap-4'>
                <OrgLogoBadge src={org?.logo} />
              </div>
            ) : (
              <div className='text-neutral-6 italic'>No logo</div>
            )}
          </div>
          <div>
            <div className='font-bold'>Org Id</div>
            <div>
              {org.orgId}
              <span className='ml-2'>
                <CopyToClipboardButton iconSize={16} text={org.orgId} className='ml-2' />
              </span>
            </div>
          </div>
        </div>
      </Card>
      <Subscription />

      <EditLogoModal org={org} updatingLogo={updatingLogo} setUpdatingLogo={setUpdatingLogo} />
      <EditNameModal org={org} updatingName={updatingName} setUpdatingName={setUpdatingName} />
    </div>
  )
}

// Reuse some of this logic in OrgOnboarding flow
export const useUpdateOrgLogo = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) => {
  const api = useApi()
  const queryClient = useQueryClient()

  const uploadLogoMutation = useMutation<void, Error, { fileName: string; file: File }>({
    mutationFn: async ({ fileName, file }) => {
      const res = await api.createOrgLogoUploadUrl({ fileName })

      logger('createOrgLogoUploadUrl res', res)
      const uploadUrl = res?.createOrgLogoUploadUrl

      if (!uploadUrl) {
        logger('Failed to get upload url')
        notify.error('Failed to get upload url')
        return
      }

      const uploadRes = await fetch(uploadUrl, {
        method: 'PUT',
        body: file,
      })

      if (!uploadRes.ok) {
        logger('Failed to upload file')
        notify.error('Failed to upload file')
      }

      logger('uploadRes', uploadRes)

      // Refresh org details
      await new Promise((resolve) => setTimeout(resolve, 5000))
      logger('refetching org')
      refetchOrgDetails({ queryClient })
      refetchOrg({ queryClient })

      // Fire success callback
      onSuccess?.()
    },
  })

  const dropzone = useDropzone({
    accept: 'image/*',
    // 500kb a good limit? No post-processing yet
    maxSize: 500 * 1024,
    maxFiles: 1,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      logger('onDrop fired', acceptedFiles, rejectedFiles)
      if (rejectedFiles.length > 0) {
        if (rejectedFiles[0].errors?.[0].code === 'file-too-large') {
          notify.error(
            `We're sorry, but the file you selected is too large. Please choose a file that's 500KB or smaller.`,
          )
        } else {
          notify.error(`Encountered an error trying to read this file. ${rejectedFiles[0]?.errors?.[0].message ?? ''}`)
        }
      }
      if (acceptedFiles.length === 0) {
        logger('no files accepted')
        return
      }

      const inputFileName = acceptedFiles[0].name

      // Get the file extension
      const fileExtension = inputFileName.split('.').pop()
      const fileName = `logo.${fileExtension}`
      uploadLogoMutation.mutateAsync({ fileName, file: acceptedFiles[0] })
    },
  })

  return {
    dropzone,
    uploadLogoMutation,
  }
}

function EditLogoModal({
  org,
  updatingLogo,
  setUpdatingLogo,
}: {
  org: GetOrg
  updatingLogo: boolean
  setUpdatingLogo: (val: boolean) => void
}) {
  const api = useApi()
  const queryClient = useQueryClient()

  const { uploadLogoMutation, dropzone } = useUpdateOrgLogo({ onSuccess: () => setUpdatingLogo(false) })

  const deleteMutation = useMutation({ mutationFn: async () => api.deleteOrgLogo() })

  async function handleDeleteLogo() {
    const res = await deleteMutation.mutateAsync()
    setUpdatingLogo(false)
    logger('deleteOrgLogo res', res)
    await new Promise((resolve) => setTimeout(resolve, 1000))
    refetchOrgDetails({ queryClient })
    refetchOrg({ queryClient })
  }

  const isPending = uploadLogoMutation.isPending || deleteMutation.isPending

  return (
    <BasicModal
      isOpen={updatingLogo}
      onClose={() => setUpdatingLogo(false)}
      title='Edit logo'
      buttons={
        <div className='flex w-full justify-between'>
          <Button kind='danger' onClick={handleDeleteLogo} disabled={isPending}>
            Delete logo
          </Button>
          <Button kind='secondary' onClick={async () => setUpdatingLogo(false)} disabled={isPending}>
            Cancel
          </Button>
        </div>
      }
    >
      <div className='flex flex-col gap-4'>
        <div className=''>
          Add a logo to help identify your organization&apos;s nodes in shared Unis. If possible, we recommend selecting
          a version of your logo that is optimized for display inside of a circle-shaped badge - a 1:1 aspect ratio is
          ideal!
        </div>
        <div className='flex justify-center gap-16 p-4 px-8'>
          <div className='flex flex-col items-center gap-2'>
            <div className='font-bold'>Good</div>
            <div>
              <OrgLogoBadge src='/logo.svg' alt='Good logo example' />
            </div>
          </div>
          <div className='flex flex-col items-center gap-2'>
            <div className='font-bold'>Better!</div>
            <div>
              <OrgLogoBadge src='/logo-symbol.svg' alt='Better logo example' />
            </div>
          </div>
        </div>
        <div
          {...dropzone.getRootProps()}
          className='border-purple-1300 bg-uibg-0 flex w-full items-center justify-center rounded-lg border-2 border-dashed p-6'
        >
          <div className='flex flex-col items-center'>
            <div className='mb-2 w-16'>
              <img src='/images/upload-cloud.svg' alt='Upload file' />
            </div>
            <input {...dropzone.getInputProps()} disabled={isPending} />
            {/* Somehow this works perfectly when these labels target an input id that doesn't exist..? */}
            {/* Adding an id to the input causes a bug where onDrop is not called the 2nd time a file is dropped */}
            {/* https://github.com/react-dropzone/react-dropzone/issues/1097 */}
            <label htmlFor={'csv-file-input'} className='text-center'>
              Drag and drop your image file here
            </label>
            <span className='my-2 uppercase'>or</span>
            <label
              htmlFor={'csv-file-input'}
              className='bg-primaryCore hover:bg-primaryCoreLight flex cursor-pointer items-center rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm'
            >
              {uploadLogoMutation.isPending && <Icon name='refresh' size={'xxs'} isSpinning className='mr-2' />}
              Upload image
            </label>
          </div>
        </div>
      </div>
    </BasicModal>
  )
}

function EditNameModal({
  org,
  updatingName,
  setUpdatingName,
}: {
  org: GetOrg
  updatingName: boolean
  setUpdatingName: (val: boolean) => void
}) {
  const api = useApi()
  const queryClient = useQueryClient()

  const defaultValues = {
    name: org?.name,
  }

  const form = useForm({
    defaultValues,
    onSubmit: async ({ value }) => {
      const response = await mutation.mutateAsync(value.name!)
      if (response.errors?.length) {
        notify.error(response.errors[0].message)
        return
      }
      notify.success(`Successfully updated Org name to ${response?.updateOrgProfile?.name}`)
      setUpdatingName(false)
      // Refresh org details
      await new Promise((resolve) => setTimeout(resolve, 1000))
      refetchOrgDetails({ queryClient })
      refetchOrg({ queryClient })
    },
  })

  const mutation = useMutation({
    mutationFn: (name: string) => api.updateOrgProfile({ name }),
  })

  const onClose = () => {
    setUpdatingName(false)
    form.update({ defaultValues })
    form.reset()
  }

  return (
    <BasicModal
      title='Edit org name'
      isOpen={updatingName}
      onClose={onClose}
      buttons={
        <form.Subscribe selector={(state) => !state.canSubmit}>
          {(submitDisabled) => (
            <div className='flex gap-2'>
              <Button kind='secondary' size={'small'} disabled={mutation.isPending} onClick={onClose}>
                Cancel
              </Button>
              <Button
                kind='primary'
                size={'small'}
                disabled={submitDisabled || mutation.isPending}
                type='submit'
                form='edit-org-name'
              >
                {mutation.isPending && <Icon className={'mr-2'} isSpinning name='refresh' size={'xs'} />}
                Save
              </Button>
            </div>
          )}
        </form.Subscribe>
      }
    >
      <Form id='edit-org-name' form={form}>
        <div className='flex flex-col gap-6'>
          <div>
            <div className='font-bold'>Your current org name is:</div>
            <div>{org?.name}</div>
          </div>
          <div className='flex gap-4'>
            <TextField
              name='name'
              label='New org name'
              placeholder='Org name'
              form={form}
              validators={createOnBlurValidator(inputValidation.isGenericNonEmptyString, true)}
            />
          </div>
        </div>
      </Form>
    </BasicModal>
  )
}
