import { ResourcesConfig } from 'aws-amplify'

import config from '../../config'

export function getAmplifyConfig() {
  const { cognito } = config
  const authConfig: ResourcesConfig['Auth'] = {
    Cognito: {
      userPoolId: cognito.USER_POOL_ID,
      userPoolClientId: cognito.USER_POOL_CLIENT_ID,
      identityPoolId: cognito.IDENTITY_POOL_ID,
    },
  }
  return {
    Auth: authConfig,
  }
}
