import { useForm } from '@tanstack/react-form'
import { useMutation } from '@tanstack/react-query'
import { Uni } from '@vendia/management-api-types'
import { GraphQLFormattedError } from 'graphql'
import Button from 'src/components/buttons/button'
import Form from 'src/components/fields/form'
import ListboxField from 'src/components/fields/listbox.field'
import TextField from 'src/components/fields/text.field'
import Icon from 'src/components/icons/icon'
import { ListOption } from 'src/components/inputs/listbox.input'
import BasicModal from 'src/components/modals/modal.basic'
import { LakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { AWS_REGIONS } from 'src/utils/csp/regions'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import notify from 'src/utils/notify'

export const ShareDataProductModal = ({
  uni,
  isOpen,
  onClose,
  shareAppConfig,
  defaultProductKey,
  onlyAllowDefaultProduct = false,
}: {
  uni: Uni
  isOpen: boolean
  onClose: () => void
  shareAppConfig: LakehouseShareAppConfig
  defaultProductKey: string | null
  onlyAllowDefaultProduct?: boolean
}) => {
  const api = useApi()
  const shareDataProduct = useMutation({
    mutationFn: async (values: {
      uniName: string
      productKey: string
      tableName: string
      grantedEmail: string
      region: string
    }) => {
      const { uniName, productKey, tableName, grantedEmail, region } = values
      return api.grantLakehouseIcebergTableAccess({ uniName, productKey, tableName, grantedEmail, region })
    },
    onSuccess: (response) => {
      if (response.errors) {
        notify.error(`Failed to send invitation: ${response?.errors?.[0]?.message}`)
        return
      }
      sharingForm.reset()
      onClose()
      notify.success('Invite sent successfully!')
    },
    onError: (error) => {
      console.error('Error sharing data product:', error)
      notify.error('Failed to send invite.')
    },
  })

  type SharingFormValues = {
    product: string
    region: string
    inviteeEmail: string
  }

  const sharingForm = useForm<SharingFormValues>({
    onSubmit: async ({ value }) => {
      const matchingProduct = shareAppConfig.products.find((p) => p.key === value.product)
      if (!matchingProduct) {
        return
      }
      const tableName = matchingProduct.tableDefinitions[0].tableName // Assuming single table for now
      await shareDataProduct.mutateAsync({
        uniName: uni.name,
        productKey: value.product,
        tableName,
        grantedEmail: value.inviteeEmail,
        region: value.region,
      })
    },
    defaultValues: {
      product: defaultProductKey || shareAppConfig.products[0].key,
      region: '',
      inviteeEmail: '',
    },
  })

  const product = sharingForm.useStore((state) => state.values.product)
  const selectedProduct = shareAppConfig.products.find((p) => p.key === product)

  return (
    <BasicModal
      className='!max-w-2xl lg:!max-w-3xl'
      title='Share data product'
      isOpen={isOpen}
      onClose={() => {
        sharingForm.reset()
        onClose()
      }}
      buttons={
        <div className='flex gap-4'>
          <Button kind='secondary' onClick={onClose}>
            Cancel
          </Button>
          <sharingForm.Subscribe selector={(state) => state.isSubmitting}>
            {(isSubmitting) => {
              return (
                <Button type='submit' form='sharingForm' data-testid='invite-submit-button' disabled={isSubmitting}>
                  {isSubmitting && <Icon isSpinning name='refresh' size={'xxs'} className='mr-1' />}
                  Send invite
                </Button>
              )
            }}
          </sharingForm.Subscribe>
        </div>
      }
    >
      <Form<SharingFormValues> className='flex-1 space-y-4 overflow-auto p-4' form={sharingForm} id='sharingForm'>
        <div className='flex flex-col gap-3'>
          {onlyAllowDefaultProduct ? null : (
            <ListboxField
              form={sharingForm}
              name='product'
              className='min-w-72'
              label='Select a product'
              options={shareAppConfig.products.map((product) => ({
                value: product.key,
                label: product.name,
              }))}
              validators={isRequiredOnBlur}
            />
          )}
          <ListboxField
            form={sharingForm}
            name='region'
            className='min-w-72'
            label='Select a region'
            validators={isRequiredOnBlur}
            options={
              AWS_REGIONS.map(({ value, displayAlt }) => ({
                value,
                label: displayAlt,
                disabled: !selectedProduct?.regions.includes(value),
                description: selectedProduct?.regions.includes(value)
                  ? null
                  : 'Product is not enabled for this region.',
              })).sort((a, b) => {
                if (a.disabled && !b.disabled) {
                  return 1
                }
                if (!a.disabled && b.disabled) {
                  return -1
                }
                return 0
              }) as ListOption[]
            }
          />
          <TextField
            form={sharingForm}
            name='inviteeEmail'
            label='Invitee email'
            description='Enter the email of the user you want to invite.'
            validators={isRequiredOnBlur}
          />
        </div>
      </Form>
    </BasicModal>
  )
}
