const schema = `{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$id": "http://vendia.com/schemas/demos/inventory-management-system.json",
  "title": "Inventory Management System",
  "description": "Store inventory data",
  "type": "object",
  "properties": {
    "Inventory": {
      "description": "Inventory",
      "type": "array",
      "items": {
        "type": "object",
        "properties": {
          "itemName": {
            "description": "Item name",
            "type": "string"
          },
          "itemNumber": {
            "description": "Item number",
            "type": "string"
          },
          "quantity": {
            "description": "Available quantity of item",
            "type": "integer"
          },
          "tags": {
            "description": "Tags associated with item",
            "type": "array",
            "items": {
              "type": "string"
            }
          }
        }
      }
    }
  }
}
  `

export default {
  schema,
}
