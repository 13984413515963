import { useMutation } from '@tanstack/react-query'
import { ShareApp, SnowflakeInfo } from '@vendia/management-api-types'
import debug from 'debug'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { CancelButton, NextButton } from 'src/components/flows/step-buttons'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { SnowflakeConnection } from 'src/components/lakehouse/snowflake-connection'
import { parseLakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { ShareAppName } from 'src/types/types'
import { assert } from 'src/utils/assert'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseFlowStepValues, LakehouseSnowflakeConnection } from 'src/utils/lakehouse/types'
import notify from 'src/utils/notify'

import { LakehouseDataLoader } from './lakehouse-loader'

const logger = debug('app:PageUpdateSnowflakeConnection')

export const PageUpdateSnowflakeConnection = () => <LakehouseDataLoader Comp={PageUpdateSnowflakeConnectionContent} />

export const PageUpdateSnowflakeConnectionContent = ({ shareApps }: { shareApps: ShareApp[] }) => {
  const navigate = useNavigate()
  const api = useApi()

  // TODO: replace this with new resolver to update snowflake connection
  // TODO: - test the connection
  //       - verify that the existing source table is still available
  //       - verify that the source table schema is the same
  //         if the schema has changed, it's possible that policies may:
  //           - have column policies for columns that don't exist (these could remain and be ignored?. mark them in UI?)
  //           - be missing policies for new columns (with PII, for example) — should warn user they may need to update policies.
  const getTablesMutation = useMutation({
    mutationFn: (connectionInput: LakehouseSnowflakeConnection) => api.getSnowflakeInfo({ connectionInput }),
    onError: (error) => notify.error(`Error testing Snowflake connection: ${error}`),
    onSuccess: (response) => {
      logger('response', response)
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.testLakehouseSnowflakeConnection) {
        notify.error('Error testing Snowflake connection')
        return
      }
      navigate('../', { relative: 'path' })
    },
  })

  const ingestionShareApp = shareApps.find(
    (shareApp) =>
      shareApp.shareAppName === ShareAppName.LakehouseIngestion ||
      shareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion ||
      shareApp.shareAppName === ShareAppName.LakehouseClouderaIngestion,
  )
  assert(ingestionShareApp, 'Lakehouse share app not found')
  const shareAppConfig = useMemo(
    () => parseLakehouseShareAppConfig(ingestionShareApp.shareAppConfig),
    [ingestionShareApp.shareAppConfig],
  )
  // Single source for now & single table for now!
  const sourceConfig = shareAppConfig.sources[0]
  const snowflakeConnection = sourceConfig.snowflakeConnection!

  return (
    // Simple single-step MultiStepFlow for editing connection (keep consistent with create flow)
    <MultiStepFlow<LakehouseFlowStepValues>
      initialFlowState={snowflakeConnection}
      flowSteps={[
        {
          id: 'update-snowflake',
          StepComponent: StepUpdateSnowflake,
        },
      ]}
      onSubmit={({ value }) => {
        if (!value.source?.snowflake) {
          throw new Error('Snowflake connection is required')
        }
        getTablesMutation.mutate(value.source.snowflake)
      }}
    />
  )
}

export const StepUpdateSnowflake: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const [, setSearchParams] = useSearchParams({ flow: 'select-type' })

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        centered
        large
        title='Update Snowflake connection'
        description={`Update the credentials used to connect to your Snowflake account in case of changes or errors.`}
      />
      <ScrollableStepContent inset verticallyCentered>
        <SnowflakeConnection context={context} />
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <CancelButton
          onClick={() => {
            context.form.reset()
            setSearchParams({ flow: 'select-type' })
          }}
        />
        <context.form.Subscribe selector={(state) => state.isSubmitting}>
          {(isSubmitting) => (
            <NextButton
              label='Update connection'
              className='!px-8'
              onClick={context.form.handleSubmit}
              isSubmitting={isSubmitting}
            />
          )}
        </context.form.Subscribe>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
