/* Roles */

import { ActionEnum, Capability, User } from '@vendia/management-api-types'

// This is rad, should use it more, shows the final result type
//  in IDE rather than GraphqlFetchResponse<...other junk...>
// https://www.totaltypescript.com/concepts/the-prettify-helper
export type Prettify<T> = {
  [K in keyof T]: T[K]
} & {} // eslint-disable-line @typescript-eslint/ban-types

export type Actions = keyof typeof ActionEnum

export enum ActionCategory {
  USER = 'USER',
  UNI = 'UNI',
  ORG = 'ORG',
  DATA = 'DATA',
}
export type ActionCategories = keyof typeof ActionCategory

export type ActionRepresentation = `Action.${Actions}`

/* Resources */

export type NameResourceString = `${string}@${string}.${string}.${string}`

export type UniResourceString =
  | `${string}.${string}.${string}.${string}`
  | `${string}.${string}.${string}.${string}#${string}`

export type VendiaResourceString =
  | `${string}`
  | `${string}/${string}`
  | `${string}/${string}/${string}`
  | `${string}/${string}/${string}/${string}`

export type NameResourceRepresentation = `NameResource(${NameResourceString})`

export type UniResourceRepresentation = `UniResource(${UniResourceString})`

export type OrgResourceRepresentation = `OrganizationResource(${string})`

export type VendiaResourceRepresentation = `VendiaResource(${VendiaResourceString})`

export interface NameResource {
  name: string
  subdomain: string
  domain: string
  ext: string
}

export interface UniResource {
  name: string
  subdomain: string
  domain: string
  ext: string
  nodeName: string
}

export interface OrgResource {
  orgId: string
}

export interface VendiaResource {
  orgId: string
  uniName: string
  nodeName: string
}

export type Resource = NameResource | UniResource | OrgResource | VendiaResource

export type ResourceRepresentation =
  | NameResourceRepresentation
  | UniResourceRepresentation
  | OrgResourceRepresentation
  | VendiaResourceRepresentation

export type RoleRepresentation = {
  [key in ActionRepresentation]?: ResourceRepresentation[]
}

export type RoleTemplate = {
  name: string
  description: string
  capabilities?: Capability[]
}

/* User Types */

export type OrgMember = User & {
  external?: boolean
}

export enum ShareAppName {
  LakehouseApp = 'lakehouse-app',
  LakehouseIngestion = 'lakehouse-ingestion',

  // Deprecated (using LakehouseIngestion instead to handle both Cloudera and Snowflake)
  LakehouseSnowflakeIngestion = 'lakehouse-snowflake-ingestion',
  LakehouseClouderaIngestion = 'lakehouse-cloudera-ingestion',
}
